<template>
  <div class="invite-tips-modal">
    <div class="page-title">{{this.$t('invite.userTitle')}}</div>
    <div class="page-icon" style="color:rgba(13, 151, 255);">
      <MailOutlined />
    </div>
    <div class="page-content">
      <div>{{`${inviter}${this.$t('invite.userContent')}`}}</div><div style="color:rgba(13, 151, 255);">{{project}}</div>
    </div>
    <div class="page-button">
      <a-button type="primary" style="width:100%" size="large" @click="$emit('joinProject')">{{this.$t('invite.userConfirm')}}</a-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import {
  MailOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'InviteTips',
  components: {
    MailOutlined
  },
  props: {
    inviter: {
      type: String,
      default: ''
    },
    project: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>
.invite-tips-modal {
  padding: 48px 48px 0;
  text-align: center;
  .page-title {
      font-size: 28px;
      font-weight: 600;
  }
  .page-icon {
      font-size: 128px;
      margin: 30px 0;
  }
  .page-content {
      font-size: 20px;
      font-weight: 600;
  }
  .page-button {
      margin-top: 136px;
      margin-bottom: 102px;
  }
}
</style>
