<template>
  <div class="login-con">
    <div class="login-header" v-show="!($store.state.isHeaderHidden)">
      <img :src="$logo || '/images/logo.png'" class="login-header-icon" height="28" alt="Logo" v-show="!($store.state.isLogoHidden)">
    </div>
    <!-- 浏览器提示 -->
    <div class="bg_img" style="background: rgba(225,225,225,0.4);">
      <!-- 星空 -->
      <Constellation></Constellation>
    </div>
    <div class="login bg-100">
      <SignIn
        v-if="isSignInShow && !(isLogin === true) && !isSuccess"
        @signIn="inviteRegister" />
      <NotFound
        @backToLogin="backToLogin"
        v-if="isInviteValid === false" />
      <SignInSuccess
        v-if="isSuccess"
        @backToLogin="backToLogin" />
      <InviteTips
        v-if="isInviteValid === true && !isSignInShow"
        @joinProject="joinProject"
        :inviter="inviter"
        :project="project"/>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import Constellation from '@/components/login/Constellation'
import SignIn from '@/components/invite/SignIn.vue'
import NotFound from '@/components/invite/NotFound.vue'
import SignInSuccess from '@/components/invite/SignInSuccess'
import InviteTips from '@/components/invite/InviteTips'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
// import { USERS } from '@/store/type'
// import { client } from '../router/path'
import api from '@/services/api.js'
// import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    Constellation,
    SignIn,
    NotFound,
    SignInSuccess,
    InviteTips
  },
  props: {
    inviteId: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      isLogin: null,
      isInviteValid: null,
      isSuccess: false,
      isSignInShow: false,
      inviter: '',
      project: '',
      orgId: null,
      projectId: null
    })

    onMounted(async () => {
      checkHref()
      await checkToken()
      await checkInviteId()
    })

    const checkHref = () => {
      console.log(route)
      const urlList = window.location.href.split('%20')
      if (urlList && urlList.length > 1) {
        window.location.href = urlList[0]
      }
    }

    const checkToken = async () => {
      const token = store.state.token
      if (token && token.length) {
        state.isLogin = true
      }
    }

    const checkInviteId = async () => {
      if (props.inviteId) {
        try {
          const res = await api.invite.checkInviteId({
            inviteId: props.inviteId
          })
          if (res && res.code === 200) {
            state.projectId = res.data?.project?.projectId
            state.orgId = res.data?.org?.orgId
            if (route?.query?.join) {
              joinProject()
            } else {
              state.inviter = res.data?.user?.nickName
              state.project = res.data?.project?.name
              state.isInviteValid = true
            }
          } else {
            state.isInviteValid = false
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    const inviteRegister = async (values = {}, isPhone) => {
      const inviteId = props.inviteId
      const { password, username, email, phone, smsCode, currentUserId } = values

      if (inviteId) {
        console.log(values)
        let data = {}
        if (isPhone) {
          data = {
            inviteId,
            currentUserId,
            type: 'sms',
            phone: phone && phone.length ? phone : undefined,
            smsCode: smsCode && smsCode.length ? smsCode : undefined,
            nickName: username && username.length ? username : undefined,
            password: password && password.length ? password : undefined
          }
        } else {
          data = {
            inviteId,
            currentUserId,
            type: 'email',
            email: email && email.length ? email : undefined,
            nickName: username && username.length ? username : undefined,
            password: password && password.length ? password : undefined
          }
        }
        try {
          console.log(api)
          const res = await api.invite.inviteRegister(data, isPhone)
          if (res && res.code === 200) {
            if (state.isLogin) {
              goToProject()
            } else {
              state.isSuccess = true
            }
          } else {
            if (state.isLogin) {
              state.isInviteValid = false
            }
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    const backToLogin = () => {
      router.push({
        name: 'Login'
      })
    }

    const goToProject = () => {
      router.push({
        name: 'Project',
        params: {
          orgId: state.orgId,
          projectId: state.projectId
        }
      })
    }

    const joinProject = async () => {
      if (state.isLogin) {
        // 获取当前登录用户信息
        const res = await api.user.getInfoV2()

        await inviteRegister({
          currentUserId: res.data.userId
        })
      } else {
        state.isSignInShow = true
      }
    }

    return {
      ...toRefs(state),
      inviteRegister,
      backToLogin,
      joinProject
    }
  }
})
</script>

<style lang="less" scoped>
.login-con {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
  .login {
    width: 576px;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 62px);
    position: absolute;
    left: 50%;
    top: calc(50% + 32px);
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.25);
    border-radius: 8px;
    background: white;
    overflow: auto;
  }
  .login-header{
    width: 100%;
    height: 62px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
    &-icon{
      margin-top:16px ;
      margin-left: 32px;
    }
  }
}

</style>
