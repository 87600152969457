<template>
  <div class="sign-in-success-modal">
    <div class="page-title">
      <CheckCircleTwoTone two-tone-color="#52c41a" />
      {{ $t('signIn.signInSuccess') }}
    </div>
    <div class="page-img-div">
      <div class="page-imgs">
        <img src="/images/download/anzhuo.png" class="page-img"/>
        <div class="page-text">{{ $t('signIn.appQR') }}</div>
      </div>
      <div class="page-imgs">
        <img src="/images/download/ios.png" class="page-img"/>
        <div class="page-text">{{ $t('signIn.iosQR') }}</div>
      </div>
    </div>
    <div class="page-content">
      {{ $t('signIn.downloadTip') }}
    </div>
    <div class="page-button">
      <a-button type="primary" style="width:100%" size="large" @click="backToLogin">{{ $t('login.login') }}</a-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import {
  CheckCircleTwoTone
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'SignInSuccess',
  components: {
    CheckCircleTwoTone
  },
  props: {},
  setup (props, context) {
    const router = useRouter()

    const state = reactive({

    })
    onMounted(async () => {

    })

    const backToLogin = () => {
      router.push({
        name: 'Login'
      })
    }

    return {
      ...toRefs(state),
      backToLogin
    }
  }
})
</script>
<style lang='less' scoped>
.sign-in-success-modal {
  padding: 48px 48px 0;
  text-align: center;
  .page-title {
      font-size: 28px;
      font-weight: 600;
  }
  .page-img-div {
      font-size: 128px;
      margin: 30px 0;
      display: flex;
      .page-imgs {
          margin: 0 auto;
          img.page-img {
              width: 177px;
          }
          .page-text {
              font-size: 18px;
          }
      }
  }
  .page-content {
      font-size: 20px;
      font-weight: 600;
  }
  .page-button {
      margin-top: 136px;
      margin-bottom: 102px;
  }
}
</style>
