<template>
  <div class="not-found-modal">
    <div class="page-title">页面无法访问</div>
    <div class="page-icon">
      <ApiOutlined />
    </div>
    <div class="page-content">
      邀请链接可能失效或无法找到
    </div>
    <div class="page-content">
      请联系邀请你的成员或管理员获取一个新的链接
    </div>
    <div class="page-button">
      <a-button type="primary" style="width:100%" size="large" @click="$emit('backToLogin')">登录</a-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import {
  ApiOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  name: 'NotFound',
  components: {
    ApiOutlined
  },
  props: {},
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>
.not-found-modal {
  padding: 48px 48px 0;
  text-align: center;
  .page-title {
      font-size: 28px;
      font-weight: 600;
  }
  .page-icon {
      font-size: 128px;
      margin: 30px 0;
  }
  .page-content {
      font-size: 20px;
      font-weight: 600;
  }
  .page-button {
      margin-top: 136px;
      margin-bottom: 102px;
  }
}
</style>
